var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-tabs",
          {
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c("el-tab-pane", { attrs: { label: "处理", name: "handled" } }),
            _c("el-tab-pane", {
              attrs: { label: "处理记录", name: "noHandled" },
            }),
            _c(_vm.activeName, {
              tag: "component",
              attrs: { inspectionType: _vm.inspectionType },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }